import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/vue/20/solid'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/vue/24/outline'
import { VBadge, VButton, VLink } from '#components'
import type { BadgeType } from '~/ui/components/Badge/badge'
import {
  type Case,
  type CaseReport,
  type CaseSummary,
  type Unit,
} from '~/repository/modules'
import type { Activity } from '~/types/Activity'
import { ActivityDescriptionTypes } from '~/types/Activity'

export function useCaseHelpers() {
  function renderCaseAddress(c: CaseSummary) {
    const addressLink = h(
      VLink,
      { to: `/units/${c.unit_id}`, class: 'whitespace-nowrap' },
      {
        default: () => {
          return `${c.unit_address_line_1} ${c.unit_address_line_2 ?? ''}`
        },
      },
    )

    return h('div', [
      addressLink,
      h('div', `${c.unit_city}, ${c.unit_state} ${c.unit_postal_code}`),
    ])
  }

  function renderCaseUnitAddress(unit: Unit) {
    const addressLink = h(
      VLink,
      { to: `/units/${unit.id}`, class: 'whitespace-nowrap' },
      {
        default: () => {
          return `${unit.address_line_1} ${unit.address_line_2 ?? ''}`
        },
      },
    )

    return h('div', [
      addressLink,
      h('div', `${unit.city}, ${unit.state} ${unit.postal_code}`),
    ])
  }

  function renderDCCV115MailOn(c: CaseSummary) {
    if (c.dc_cv_115_mailed_at)
      return h('span', c.dc_cv_115_mailed_at)
    return h('span', 'N/A')
  }

  function renderWorkflowStepBadge(c: CaseSummary | Case) {
    const workflowStore = useWorkflowStore()

    const step = workflowStore.getWorkflowStep(c.workflow_step_id)

    if (step)
      return h(VBadge, { type: 'success' }, () => step.name)

    return h('span')
  }

  function renderCaseActivityActionBadge(activity: Activity) {
    let badgeType: BadgeType = 'info'

    switch (activity.description) {
      case ActivityDescriptionTypes.created:
        badgeType = 'success'
        break
      case ActivityDescriptionTypes.note:
        badgeType = 'warning'
    }

    return h(VBadge, { type: badgeType }, () =>
      activity.description.toUpperCase(),
    )
  }

  function renderCaseActivityMessage(activity: Activity) {
    if (activity.workflow_step_change) {
      return h('span', [
        h('span', { class: 'pr-2' }, () => 'Moved to workflow step'),
        h('em', activity.workflow_step_change),
      ])
    }

    if (activity.note && !isArray(activity.note))
      return h('span', (activity.note as { note: string }).note)

    return h('span')
  }

  function renderCaseActivityChanges(activity: Activity) {
    if (activity.changes.attributes) {
      return h(VBadge, { size: 'xs', type: 'warning' }, [
        h('span', JSON.stringify(activity.changes.attributes)),
      ])
    }

    return h('span')
  }

  function renderCaseLocation(caseReport: CaseReport) {
    const locationArr = caseReport.location.split('/')
    const location = locationArr[locationArr.length - 1]

    return h('span', location)
  }

  const { renderDateTime } = useCommonHelpers()

  function renderReportCreatedAt(caseReport: CaseReport) {
    return h('span', renderDateTime(caseReport.created_at))
  }

  function renderSummary(date: string) {
    const dateArr = date.split('_')

    return h('span', dateArr[0])
  }

  function renderCaseNotesCount(count: number, toggle: () => void) {
    return h('div', { class: 'flex flex-row items-center space-x-2' }, [
      h('span', count),
      h(ChatBubbleBottomCenterTextIcon, {
        class: 'h-4 h-4 text-indigo-500 cursor-pointer',
        onClick: toggle,
      }),
    ])
  }

  function renderCaseBatchId(batchId: string, batchCreatedAt: string) {
    return h('div', [
      batchCreatedAt ? renderDateTime(batchCreatedAt) : 'N/A',
      batchId ? ` - ${batchId}` : '',
    ])
  }

  function renderCaseWorBatchId(worBatchId: string, worBatchCreatedAt: string) {
    return h('div', [
      worBatchCreatedAt ? renderDateTime(worBatchCreatedAt) : 'N/A',
      worBatchId ? ` - ${worBatchId}` : '',
    ])
  }

  function renderYesNoBadgeToggle(value: boolean, color = true, onClick: () => void) {
    const label = value ? 'Yes' : 'No'
    const textColor = value ? color : !color
    return h(VButton, { class: 'whitespace-nowrap cursor-pointer', onClick }, [
      h(textColor ? CheckCircleIcon : MinusCircleIcon, {
        class: textColor
          ? 'text-green-500 h-4 w-4 inline'
          : 'text-red-500 h-4 w-4 inline',
      }),
      h('span', label),
    ])
  }

  return {
    renderCaseAddress,
    renderDCCV115MailOn,
    renderWorkflowStepBadge,
    renderCaseActivityActionBadge,
    renderCaseActivityMessage,
    renderCaseActivityChanges,
    renderCaseLocation,
    renderReportCreatedAt,
    renderSummary,
    renderCaseUnitAddress,
    renderCaseNotesCount,
    renderCaseBatchId,
    renderCaseWorBatchId,
    renderYesNoBadgeToggle,
  }
}
